<template>
  <div class="iss-main-grid">
    <div class="iss-search-wrap">
      <div class="title">
        <div class="contentTitle">
          <div class="contentOne">全部关系组</div>
          <div class="marginTop5">
            查看并管理你的关系组，可点击联系人并将其拖入关系组中。
          </div>
        </div>
        <div class="titleRight cursor-p titleRight-nav">
          <a-button type="primary" class="marginRight5" @click="handleAddGroup"
            >新增关系组</a-button
          >
        </div>
      </div>
      <!--    主内容区   @click="goToDashboard({ importStatus: 1 })"  -->
      <a-row :gutter="16" class="contentClass">
        <a-col class="gutter-row" :span="12">
          <a-row type="flex" justify="start" class="gutter-box kapianOne">
            <a-col :span="6">
              <div class="titleOneLeft">{{ typeText }}</div>
            </a-col>
            <a-col :span="18">
              <div class="titleOneZhong">
                <!--                <div   @click="handleClick(item)">-->
                <!--                  <component :is="icons['FunnelPlotOutlined']" />-->
                <!--                  筛选-->
                <!--                </div>-->
                <div class="search-area">
                  <a-input-search
                    allow-clear
                    v-model:value="searchArr"
                    autocomplete="off"
                    placeholder="搜索姓名"
                    @search="handleClickSearch"
                  />
                </div>

                <div class="operate">
                  <a-popover placement="bottom">
                    <template #content>
                      <div
                        class="marginBottom5"
                        style="cursor: pointer"
                        @click="handleAllList(0)"
                      >
                        未分组联系人
                      </div>
                      <div
                        class="marginTop5"
                        style="cursor: pointer"
                        @click="handleAllList(1)"
                      >
                        全部联系人
                      </div>
                    </template>

                    <div class="left cursor-p">
                      <component :is="icons['FunnelPlotOutlined']"> </component>
                      筛选
                    </div>
                  </a-popover>
                </div>

                <a-button type="primary" class="right" @click="handleGroup"
                  >分组</a-button
                >
              </div>
            </a-col>
          </a-row>

          <a-spin :spinning="spinning">
            <a-checkbox-group
              v-model:value="form.checkedId"
              class="item-list"
              @scroll="handleScroll"
            >
              <a-checkbox
                v-for="item in groupList"
                :key="item.id"
                :value="item.id"
              >
                <div class="isContent">
                  <div class="header">
                    <a-avatar
                      v-if="item.avatar"
                      :src="setAvatar(item.avatar)"
                      :size="24"
                      class="avatarClass"
                      @click="handleGoToContact(item.id)"
                    />
                    <span
                      v-else
                      @click="handleGoToContact(item.id)"
                      class="headerText avatarClass"
                    >
                      <span v-if="item.name">
                        {{ item.name[0] }}
                      </span>
                    </span>
                  </div>

                  <div class="nameText">{{ item.name }}</div>
                </div>
              </a-checkbox>
            </a-checkbox-group>
          </a-spin>
        </a-col>
        <a-col class="gutter-row" :span="12">
          <a-row type="flex" justify="start" class="gutter-box kapianTwo">
            <a-col :span="24" class="contentRight">
              <div class="titleTwoRight titleOneLeft">关系组</div>
              <div class="iss-pulse">
                <a-spin :spinning="spinningTwo">
                  <a-collapse
                    v-model:activeKey="activeKey"
                    :bordered="false"
                    accordion
                    v-for="item in treeList"
                    :key="item.id"
                  >
                    <template #expandIcon="{ isActive }">
                      <caret-right-outlined :rotate="isActive ? 90 : 0" />
                    </template>
                    <a-collapse-panel
                      :key="item.id"
                      :expandIconPosition="left"
                      :class="{ showBack: activeKey === item.id }"
                      @click="handleHeader(item)"
                    >
                      <template #header>
                        <div class="headerContent">

                          <component
                            :is="icons[`${item.icon}` || 'FolderOpenOutlined']"
                            class="activeColor marginRight5"
                            style="color: #ff9533"
                          ></component>

                          <div class="groupName" @click="handleItem(item)">
                            {{ item.groupName }}123
                          </div>
                          <div class="size">{{ item.size }}人</div>
                          <div class="description">{{ item.description }}</div>
                          <div @click="handleItem(item)">
                            <!-- <img
                              src="@/assets/images/box/zhankai.png"
                              alt=""
                              class="zhankai"
                            /> -->
                            <EyeOutlined class="zhankai" />
                          </div>
                        </div>
                      </template>

                      <div
                        v-for="(i, index) in item.children"
                        :key="i.key"
                        @click="handleItem(i)"
                        class="panel"
                        :class="{
                          borB:
                            item.children.length - 1 === index ? true : false,
                        }"
                      >
                        <!--<img-->
                        <!--  src="@/assets/images/box/frequenct.png"-->
                        <!--  alt=""-->
                        <!--  class="frequenct-img"-->
                        <!--  :class="{ activeClass: i.id === showBut }"-->
                        <!--/>-->

                        <div class="headerContentT" @click="handleHeader(item)">
                          <component
                            :is="icons[`${i.icon}`]"
                            class="activeColor mr-12 ml-12"
                          ></component>
                          <div class="groupName">{{ i.groupName }}</div>
                          <div class="size">{{ i.size }}人</div>
                          <div class="description">{{ i.description }}</div>
                        </div>
                      </div>
                    </a-collapse-panel>
                  </a-collapse>
                </a-spin>
              </div>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
  </div>
  <iss-add-group
    v-model:visible="visibleAddGroup"
    :init="activeItem"
    @fnOk="handleOkGroup"
  />
  <add-ties-group
    v-model:visible="visibleAddEditGroup"
    :init="activeItemAddEdit"
    @fnOk="handleAddEditGroup"
  />
</template>

<script>
import {
  Spin,
  Row,
  Col,
  Popover,
  Checkbox,
  message,
  Collapse,
  Avatar,
} from 'ant-design-vue';
import * as icons from '@ant-design/icons-vue';
import groupApi from '@/api/group';
import { onMounted, reactive, ref, toRefs, watch } from 'vue';
import { useStore } from 'vuex';
import IssAddGroup from '@/views/contacts/contactAall/addGroup';
import { setAvatar } from '@/utils';
import { useRouter } from 'vue-router';
import addEditGroup from '@/views/group/addEditGroup';
// import { CaretRightOutlined } from '@ant-design/icons-vue';
import addTiesGroup from '@/views/contacts/contactAall/addTiesGroup';
import menuApi from '@/api/menu';

export default {
  components: {
    ARow: Row,
    ACol: Col,
    Icon: icons,
    ASpin: Spin,
    APopover: Popover,
    ACheckbox: Checkbox,
    ACheckboxGroup: Checkbox.Group,
    IssAddGroup,
    ACollapse: Collapse,
    ACollapsePanel: Collapse.Panel,
    AAvatar: Avatar,
    // CaretRightOutlined,
    addEditGroup,
    addTiesGroup,
    CaretRightOutlined: icons.CaretRightOutlined,
    EyeOutlined: icons.EyeOutlined,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const userId = store.state.account.user.id;
    const state = reactive({
      groupList: [],
      typeText: '未分组联系人',
      visibleAddGroup: false,
      visibleAddEditGroup: false,
      activeItemAddEdit: {},
      activeItem: {},
      treeList: [],
      activeKey: '',
      showBut: '',
      spinning: false,
      spinningTwo: false,
    });
    const searchArr = ref('');
    const listType = ref(0);
    const form = reactive({
      checkedId: [],
      connectionsId: '',
    });
    const page_setting = ref({
      current: 1,
      size: 20,
    });

    // 全部关系组
    const getList = type => {
      state.spinning = true;
      groupApi
        .contactUnGroupOrAllGroup('', {
          type: type ? type : 0,
          userId: userId,
          current: page_setting.value.current,
          size: page_setting.value.size,
          name: searchArr.value,
        })
        .then(res => {
          if (res) {
            state.groupList = res.records;
            state.spinning = false;
            form.checkedId = [];
            setTimeout(() => {
              state.spinning = false;
            }, 1000);
          }
        });
    };

    const getTreeList = () => {
      groupApi.getContactGroupsList('', userId).then(res => {
        if (res) {
          Object.assign(state.treeList, res);
          setTimeout(() => {
            state.spinningTwo = false;
          }, 1000);
        }
      });
    };

    const getMenuList = () => {
      menuApi
        .getList({
          userId: store.state.account.user.id,
        })
        .then(res => {
          if (res) {
            res = res.map(i => {
              const obj = {
                id: i.id,
                parentId: i.parentId,
                path: '/group/groupItem?id=' + i.id,
                name: 'groupItem',
                menuKey: 'groupItem',
                type: 'groupItem',
                component: 'group/groupItem',
                accessWay: 'builtin',
                description: i.description,
                meta: {
                  title: i.groupName,
                  icon: i.icon,
                  breadcrumb: true,
                  size: i.size,
                },
                hidden: false,
                alwaysShow: false,
              };
              if (i.children?.length) {
                i.children = i.children.map(item => {
                  return {
                    id: item.id,
                    parentId: item.parentId,
                    path: '/group/groupItem?id=' + item.id,
                    name: 'groupItem',
                    menuKey: 'groupItem',
                    type: 'groupItem',
                    component: 'group/groupItem',
                    accessWay: 'builtin',
                    description: item.description,
                    meta: {
                      title: item.groupName,
                      icon: item.icon,
                      breadcrumb: true,
                      size: item.size,
                    },
                    hidden: false,
                    alwaysShow: false,
                  };
                });
                return {
                  ...obj,
                  children: i.children,
                };
              } else {
                return obj;
              }
            });
            let target = [
              {
                path: '/groupMenu',
                // path: '',
                name: 'groupMenu',
                menuKey: 'groupMenu',
                component: 'Layout',
                accessWay: 'builtin',
                description: '关系组关系组',
                meta: {
                  title: '关系组',
                  icon: 'custom',
                  breadcrumb: true,
                  select: '@/assets/images/menu/groupS.png',
                  unSelect: '@/assets/images/menu/group.png',
                },
                hidden: false,
                alwaysShow: false,
                children: [],
              },
            ];
            target[0].children = res;
            store.commit('account/setCustomMenus', target);
          }
        });
    };

    onMounted(() => {
      getList();
      getTreeList();
    });

    watch(
      () => form.checkedId,
      value => {
        console.log(value);
      }
    );

    const handleAddEditGroup = value => {
      state.visibleAddEditGroup = value;
      state.spinningTwo = true;
      getList();
      getTreeList();
      getMenuList();
    };

    return {
      icons,
      ...toRefs(state),
      form,
      setAvatar,
      getTreeList,
      searchArr,
      listType,
      handleAddEditGroup,
      handleAllList(type) {
        listType.value = type;
        state.spinning = true;
        getList(type);
        if (type === 0) {
          state.typeText = '未分组联系人';
        } else {
          state.typeText = '全部联系人';
        }
      },
      handleAddGroup() {
        state.visibleAddEditGroup = true;
        state.activeItemAddEdit = {
          type: 'add',
          show: false,
          groupMessage: {
            parentId: '0',
            id: '0',
          },
        };
      },

      handleOkGroup(value) {
        state.visibleAddGroup = value;
        state.spinning = true;
        getList();
        getTreeList();
      },
      handleGroup() {
        if (form.checkedId.length === 0) {
          return message.error('请先选择联系人');
        } else {
          console.log('form.checkedId', form.checkedId);
          state.activeItem = form.checkedId;
          state.visibleAddGroup = true;
        }
      },

      handleItem(i) {
        state.showBut = i.id;
        form.connectionsId = i.id;
        router.push(`/group/groupItem?id=${i.id}`);
      },

      handleHeader(item) {
        console.log('item', item);
      },

      handleGoToContact(id) {
        router.push(`/contacts/${id}`);
      },

      handleScroll(e) {
        const { scrollTop, clientHeight, scrollHeight } = e.target;
        if (scrollTop + clientHeight >= scrollHeight) {
          console.log('滚动条到达底部');
          state.spinning = true;
          page_setting.value.current = page_setting.value.current + 1;
          groupApi
            .contactUnGroupOrAllGroup('', {
              type: listType.value ? listType.value : 0,
              userId: userId,
              current: page_setting.value.current,
              size: page_setting.value.size,
              name: searchArr.value,
            })
            .then(res => {
              if (res) {
                if (res.records.length > 0) {
                  res.records.forEach(element => {
                    state.groupList.push(element);
                  });
                }

                state.spinning = false;
                setTimeout(() => {
                  state.spinning = false;
                }, 1000);
              }
            });
        }
      },

      handleClickSearch(e) {
        state.spinning = true;
        page_setting.value.current = 1;
        groupApi
          .contactUnGroupOrAllGroup('', {
            type: listType.value ? listType.value : 0,
            userId: userId,
            current: page_setting.value.current,
            size: page_setting.value.size,
            name: e,
          })
          .then(res => {
            if (res) {
              state.groupList = res.records;
              state.spinning = false;
              setTimeout(() => {
                state.spinning = false;
              }, 1000);
            }
          });
      },
    };
  },
};
</script>

<style scoped lang="less">
.iss-main-grid {
  min-height: calc(100% - 32px);
  max-height: calc(100vh - 88px);
  overflow-y: auto;
  background: #fff;
  padding: 32px 54px;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .contentTitle {
    .contentOne {
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      color: #333333;
    }
    .marginTop5 {
      color: #666666;
    }
  }
  .titleRight {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 30%;

    .titleRight-nav {
      display: flex;
      justify-content: right;
      :deep(.ant-btn) {
        background: #ff7b00;
        border: 1px solid #ff7b00;
        color: #fff;
      }
    }
  }
}
.contentClass {
  margin-top: 20px;

  // .contentRight {
  //   padding: 0px 20px;
  // }

  .titleOneLeft {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.9);
  }
  .titleOneZhong {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 28px;
    .left {
      border: 1px solid #eeeff5;
      background: #ffffff;
      color: #6b7592;
      padding: 4px 13px;
      border-radius: 4px;
      margin-right: 10px;
    }
  }
}
.item-list {
  display: grid;
  // max-height: calc(100vh - 315px);
  max-height: 700px;
  overflow-y: auto;
  margin: 20px;

  :deep(.ant-checkbox-wrapper) {
    margin-left: 0px;
  }

  :deep(label.ant-checkbox-wrapper) {
    border: 0.5px solid #eeeeee;
    padding: 8px 28px;
    border-right: none;
    border-left: none;

    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .avatarClass {
    margin: 0px 8px;
  }
  .headerText {
    display: inline-block;
    //text-align: center;
  }
  .isContent {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.showBack /deep/ .ant-collapse-header {
  background: rgba(255, 123, 0, 0.08);
}
.iss-pulse {
  max-height: calc(100vh - 315px);
  overflow-y: auto;

  .panel {
    //margin-left: 24px;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    //margin: 4px 12px;
    padding: 12px 12px;
    border-bottom: 0.5px solid #eeeeee;
  }

  .borB {
    border-bottom: 0px;
  }
  :deep(.ant-collapse-borderless) {
    background: #ffffff;
  }
  :deep(.ant-collapse-header) {
    border: 0.5px solid #eeeeee;
    border-right: none;
    border-left: none;
  }
  :deep(.ant-collapse-content > .ant-collapse-content-box) {
    padding-bottom: 5px;
    min-height: 32px;
  }

  .headerContent {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .groupName {
      width: 160px;
      margin-left: 8px;
      white-space: nowrap; /*超出的空白区域不换行*/
      overflow: hidden; /*超出隐藏*/
      text-overflow: ellipsis; /*文本超出显示省略号*/
    }
    .size {
      margin: 0px 10px;
      width: 80px;
    }
    .description {
      width: 206px;
      white-space: nowrap; /*超出的空白区域不换行*/
      overflow: hidden; /*超出隐藏*/
      text-overflow: ellipsis; /*文本超出显示省略号*/
    }
  }
}
.frequenct-img {
  //margin-right: 12px;
  display: none;
  width: 13px;
  height: 13px;
  position: absolute;
  left: 30px;
}
.activeClass {
  display: block;
}
.iss-pulse {
  margin: 20px;
}

.iss-pulse /deep/ .ant-collapse-borderless > .ant-collapse-item {
  border-bottom: none;
}
.iss-pulse /deep/ .ant-collapse > .ant-collapse-item {
  border-bottom: none;
}

//.headeIcon {
//  color: #FF9533 !important;
//}
.iss-pulse
  /deep/
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  color: #ff9533 !important;
}
.panel {
  .headerContentT {
    display: flex;
    justify-content: flex-start;
    .groupName {
      width: 160px;
      white-space: nowrap; /*超出的空白区域不换行*/
      overflow: hidden; /*超出隐藏*/
      text-overflow: ellipsis; /*文本超出显示省略号*/
    }
    .size {
      margin: 0px 10px;
      width: 80px;
    }
    .description {
      width: 168px;
      white-space: nowrap; /*超出的空白区域不换行*/
      overflow: hidden; /*超出隐藏*/
      text-overflow: ellipsis; /*文本超出显示省略号*/
    }
  }
}
.zhankai {
  width: 10px;
  height: 6px;
}

.search-area {
  margin-right: 10px;
}
</style>
